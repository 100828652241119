import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const CompanyRow = (props) => {

  const numberOfAssociatedCompanies = props.allCompanies.filter(x => x.associateId === props.account.associateId);
  const numberOfAssociatedEmployees = props.allEmployees.filter(x => x.associateId === props.account.associateId);
  let totalProjectsCount = 0;
  numberOfAssociatedCompanies.forEach(company => {
    const projectsArray = company.projects;
    const projectsCount = projectsArray ? projectsArray.length : 0;
    totalProjectsCount += projectsCount;
  });
  const companyAssociate = props.allOwnerCompanies.filter(x => x.associateId === props.account.associateId);

  const calculateTrialDaysLeft = () => {
        if (props.account.license === 'Trail') {
            const startDate = moment(props.account.createdAt);
            const end = moment();
            const duration = moment.duration(end.diff(startDate));
            const daysLeft = 30 - duration.asDays();
            return Math.max(0, Math.round(daysLeft));
        }
        return 0;
    };

    const daysLeft = calculateTrialDaysLeft();

    const [plan, setPlan] = useState(props.plans.find(x => x.id === props.account.planId))

  return (
    <>
      <tr key={props.account.id}>
        <td>{moment(props.account.createdAt).format('DD/MM/YYYY')}</td>
        <td><b>{props.account.firstName} {props.account.lastName}</b></td>
        <td><b>{companyAssociate.length > 0 && <>{companyAssociate[0].companyName}</>}</b></td>
        <td>{props.account.email}</td>
        <td>{props.account.employees.length}</td>
        <td>{numberOfAssociatedCompanies.length}</td>
        <td>{totalProjectsCount}</td>
        <td>{numberOfAssociatedEmployees.length}</td>
        <td>{plan?.planName}</td>
        <td>{moment(props.account.expireDate).format('DD/MM/YYYY')}</td>
        <td>
            <Link className='btn btn-success' to={{pathname: `/account-details/${props.account.associateId}`}}>פרטים</Link>
        </td>
      </tr>
    </>
  );
};

export default CompanyRow;
