import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import * as XLSX from "xlsx";
import { auth, database, storage } from "../../services/firebase-config";
import { arrayUnion, doc,arrayRemove,getDoc, addDoc, deleteDoc, collection, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { FaRegEdit } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import moment from "moment";
import { FcBusinessman, FcDepartment, FcInTransit, FcBriefcase,FcGenealogy } from "react-icons/fc";
import EditCompany from "../../components/EditCompany";
import AddProject from "../../components/AddProject";
import SiteSmall from "../../components/SiteSmall";
import AddEmployee from "../../components/AddEmployee";
import EmployeeSmall from "../../components/EmployeeSmall";
import EmployeeCard from "../../components/EmployeeCard";
import ProjectCard from "../../components/ProjectCard";
import AddMachine from "../../components/AddMachine";
import MachineRow from "../../components/MachineRow";
import useDashboardStore from "../../store/useDashboardStore";
import DepartmentRow from "../../components/DepartmentRow";
import {Modal,Button} from 'react-bootstrap';
import { FaRegFilePdf } from "react-icons/fa";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";

const CompanyEdit = () => {
  const { id, view } = useParams();
  const navigate = useNavigate();


  const {
    userPersonalData,
    companies,
    employees,
    periodics,
    updateCompanyInStore,
    addProjectToStore,
    removeProjectFromStore,
    updateProjectInStore,
    addEmployeeToStore,
    removeEmployeeFromStore,
    updateEmployeeInStore,
    updateEmployeeFileInStore,
    myPlan
  } = useDashboardStore();


  const [company, setCompany] = useState({});
  const [companyEditView, setCompanyEditView] = useState(false);
  const [addProjectView, setAddProjectView] = useState(false);
  const [addEmployeeView, setAddEmployeeView] = useState(false);
  const [addMachineView, setAddMachineView] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [myAccount, setMyAccount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedProject, setSelectedProject] = useState(false);
  const [progress, setProgress] = useState(0);
  const [companyView, setCompanyView] = useState("edit");
  const [data, setData] = useState([]);
  const [propsData, setPropsData] = useState([]);
  const [employeesArr, setEmployeesArr] = useState([]);
  const [machineFilter, setMachineFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [machines, setMachines] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departmentsOrig, setDepartmentsOrig] = useState([]);
  const [machinesOrig, setMachinesOrig] = useState([]);
  const [periodicList, setPeriodicList] = useState([]);
  const [assetsCategoriesList, setAssetsCategoriesList] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  const [constructorList, setConstructorList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedConstruction, setSelectedConstructor] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedMachineType, setSelectedMachineType] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [departmentName, setDepartmentName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isImportEmployeeAllow, setIsImportEmployeeAllow] = useState(true);
  const [showPropsModal, setShowPropsModal] = useState(false);
  const exportRef = React.useRef();

  const sanitizeEmployeeData = (employee) => {
    return {
      ...employee,
      constructor: employee.constructor && typeof employee.constructor === 'object' && Object.keys(employee.constructor).length === 0 ? '' : employee.constructor || '',
      branch: employee.branch || '',
      company: employee.company || '',
    };
  };

  const [sitesCount, setSitesCount] = useState(0);




  useEffect(() => {

    const companyEmployees = employees.filter(x => x.associateCompany === id);
    const sanitizedEmployees = companyEmployees.map(sanitizeEmployeeData);

    const allEmployees = sanitizedEmployees
      .filter((x) => x.associateCompany === id)
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
    setEmployeesArr(allEmployees);


  
    const uniquePositions = Array.from(new Set(sanitizedEmployees.map((employee) => employee.position).filter((position) => position)));


    const uniqueConstructors = Array.from(new Set(sanitizedEmployees.map((employee) => employee.constructor).filter((constructor) => constructor)));
    const uniqueBranch = Array.from(new Set(sanitizedEmployees.map((employee) => employee.branch).filter((branch) => branch)));
    const uniqueCompany = Array.from(new Set(sanitizedEmployees.map((employee) => employee.company).filter((company) => company)));
  
    const allPeriodics = periodics.filter(x => x.associateId === userPersonalData.associateId);
    setPeriodicList(allPeriodics);

    setPositionsList(uniquePositions);

    setCompanyList(uniqueCompany);
    setBranchList(uniqueBranch);
    setConstructorList(uniqueConstructors);
  
    const companyObj = companies.find((x) => x.id === id);
    setCompany(companyObj || {});
  
    // Calculate total sites
    const totalSites = companies.reduce((total, company) => total + (company.projects ? company.projects.length : 0), 0);
    setSitesCount(totalSites);
  
    // Set departments if companyObj exists
    if (companyObj) {
      // Check and set departments
      setDepartments(companyObj.departments || []);
      console.log('Departments length:', companyObj.departments ? companyObj.departments.length : 0);
  
      // Check and set machines if they exist
      if (companyObj.machines && companyObj.machines.length > 0) {
        const uniqueDepartment = Array.from(
          new Set(companyObj.machines.map((machine) => machine.department).filter(Boolean))
        );
        setDepartmentList(uniqueDepartment);
        setMachines(companyObj.machines);
        setMachinesOrig(companyObj.machines);
      }
  
      // Original departments
      setDepartmentsOrig(companyObj.departments || []);
    }
  }, [employees, companies, id]);
  
  
  

  useEffect(() => {
    if (view) {
      setCompanyView(view);
    }
  }, [view]);

  const handleDelete = () => {
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    setShowConfirmation(false);
    onDelete();

    toast.success("החברה הוסרה בהצלחה", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const onDelete = async () => {
    try {
      const companiesRef = doc(database, "companies", id);
      await updateDoc(companiesRef, {
        isArchive: true,
      }).then((result) => {
        navigate("/companies");
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };


  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        setData(data);

        const employeesExist = employees.length;
        const employeesToImport = data.length;
        const sumOfEmployees = employeesExist + employeesToImport;
        if(sumOfEmployees > myPlan.employees){
          setIsImportEmployeeAllow(false)
          toast.error("כמות העובדים גדולה ממספר העובדים במסלול");
        } else {
          setShowModal(true);
          setIsImportEmployeeAllow(true)
        }
    };
};

  const handleFileUploadForProps = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (evt) => {
      const data = evt.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setPropsData(parsedData);
      setShowPropsModal(true); // Show modal with the preview of the data
    };
  };

  const updateCompany = async (name, address, city, email, logo, phone, taxid) => {

    try {
      const companyRef = doc(database, "companies", id);

      if (logo && logo.name) {
        const storageRef = ref(storage, `files/${logo.name}`);
        const uploadTask = uploadBytesResumable(storageRef, logo);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(prog);
          },
          (error) => console.log(error),
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then(async (downloadURL) => {
                await updateDoc(companyRef, {
                  companyName: name,
                  companyAddress: address,
                  companyCity: city,
                  companyEmail: email,
                  companyTaxId: taxid,
                  companyPhone: phone,
                  companyLogo: downloadURL,
                });
                return downloadURL;
              })
              .then((downloadURL) => {
                updateCompanyInStore({
                  id,
                  companyName: name,
                  companyAddress: address,
                  companyCity: city,
                  companyEmail: email,
                  companyTaxId: taxid,
                  companyPhone: phone,
                  companyLogo: downloadURL,
                });
                setCompanyEditView(false);
              });
          }
        );
      } else {
        console.log("NO LOGO PROVIDED");
        await updateDoc(companyRef, {
          companyName: name,
          companyAddress: address,
          companyCity: city,
          companyEmail: email,
          companyTaxId: taxid,
          companyPhone: phone,
          companyLogo: company.companyLogo, // Retain the existing logo
        }).then((res) => {
          updateCompanyInStore({
            id,
            companyName: name,
            companyAddress: address,
            companyCity: city,
            companyEmail: email,
            companyTaxId: taxid,
            companyPhone: phone,
            companyLogo: company.companyLogo, // Retain the existing logo
          });
          setCompanyEditView(false);
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const addNewProject = async (
    projectAddress,
    projectCity,
    projectDesc,
    projectImage,
    projectLogo,
    projectName
  ) => {
    const objid = generateRandomId();

    try {
      const new_project = {
        siteId: objid,
        createdBy: auth.currentUser.uid,
        createdAt: Date.now(),
        projectName: projectName,
        projectAddress: projectAddress,
        projectCity: projectCity,
        projectDesc: projectDesc,
        projectImage: projectImage,
        projectLogo: projectLogo,
        associateId: userPersonalData.associateId,
      };
      const companyRef = doc(database, "companies", id);
      await updateDoc(companyRef, {
        projects: arrayUnion(new_project),
      }).then(async (res) => {
        addProjectToStore(id, new_project);
        setAddProjectView(false);
        toast.success(`אתר ${projectName} התווסף בהצלחה`);
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const removeProject = async (project) => {
    console.log("SITE: " + JSON.stringify(project));
    try {
      const companyRef = doc(database, "companies", id);
      await updateDoc(companyRef, {
        projects: arrayRemove(project),
      }).then((res) => {
        removeProjectFromStore(id, project.siteId);
        setSelectedProject(null);
        toast.success("ההסרה התבצעה בהצלחה");
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateProject = async (
    projectAddress,
    projectCity,
    projectDesc,
    projectImage,
    projectName,
    siteId
  ) => {
    const companyRef = doc(database, "companies", id);
    getDoc(companyRef)
      .then(async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const dataArray = docSnapshot.data().projects;

          const updatedObjectIndex = dataArray.find((obj) => obj.siteId === siteId);

          updatedObjectIndex.projectName = projectName;
          updatedObjectIndex.projectAddress = projectAddress;
          updatedObjectIndex.projectCity = projectCity;
          updatedObjectIndex.projectDesc = projectDesc;

          if (projectImage) {
            const storageRef = ref(storage, `files/${projectImage.name}`);
            const uploadTask = uploadBytesResumable(storageRef, projectImage);

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(prog);
              },
              (error) => console.log(error),
              async () => {
                try {
                  const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                  updatedObjectIndex.projectImage = downloadURL;
                  setSelectedProject({
                    projectName: projectName,
                    projectDesc: projectDesc,
                    projectCity: projectCity,
                    projectAddress: projectAddress,
                    associateId: updatedObjectIndex.associateId,
                    createdAt: updatedObjectIndex.createdAt,
                    createdBy: updatedObjectIndex.createdBy,
                    siteId: updatedObjectIndex.siteId,
                    projectImage: downloadURL,
                  });

                  await updateDoc(companyRef, { projects: dataArray })
                    .then((res) => {
                      updateProjectInStore(id, updatedObjectIndex);
                    })
                    .catch((updateError) => {
                      console.error("Error updating document:", updateError);
                    });
                } catch (urlError) {
                  console.error("Error getting download URL:", urlError);
                }
              }
            );
          } else {
            setSelectedProject({
              projectName: projectName,
              projectDesc: projectDesc,
              projectCity: projectCity,
              projectAddress: projectAddress,
              associateId: updatedObjectIndex.associateId,
              createdAt: updatedObjectIndex.createdAt,
              createdBy: updatedObjectIndex.createdBy,
              siteId: updatedObjectIndex.siteId,
              projectImage: updatedObjectIndex.projectImage,
            });

            updateDoc(companyRef, { projects: dataArray })
              .then((res) => {
                updateProjectInStore(id, updatedObjectIndex);
              })
              .catch((updateError) => {
                console.error("Error updating document:", updateError);
              });
          }
        }
      })
      .catch((error) => {
        console.error("Error getting document:", error);
      });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedEmployees([]);
    } else {
      const allEmployeeIds = filteredEmployees.map((emp) => emp.id);
      setSelectedEmployees(allEmployeeIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectEmployee = (id, isSelected) => {
    if (isSelected) {
      setSelectedEmployees([...selectedEmployees, id]);
    } else {
      setSelectedEmployees(selectedEmployees.filter((empId) => empId !== id));
    }
  };

  const deleteSelectedEmployees = async () => {
    const isConfirmed = window.confirm("האם למחוק את העובדים הנבחרים לצמיתות?");
    if (isConfirmed) {
      const promises = selectedEmployees.map((id) => {
        return deleteDoc(doc(database, "employees", id));
      });

      try {
        await Promise.all(promises);
        selectedEmployees.forEach((id) => removeEmployeeFromStore(id));
        toast.success("עובדים נמחקו בהצלחה");
        setSelectedEmployees([]); // Clear selection after deletion
      } catch (error) {
        toast.error("התרחשה שגיאה במחיקת העובדים: " + error.message);
      }
    }
  };

  const deleteEmployee = async (id) => {
    const isConfirmed = window.confirm("האם למחוק את העובד לצמיתות?");
    if (isConfirmed) {
      try {
        await deleteDoc(doc(database, "employees", id)).then((result) => {
          removeEmployeeFromStore(id);
          toast.success("העובד נמחק בהצלחה");
        });
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const addNewEmployee = async (
    firstName,
    lastName,
    address,
    email,
    mobile,
    position,
    employeeId,
    companyVal,
    branch,
    constructor,
    employeeNumber,
    hospitality,
    department
  ) => {
    try {
      const newEmployee = {
        associateId: userPersonalData.associateId,
        address: address,
        employeeId: employeeId,
        phone: mobile,
        position: position,
        createdBy: auth.currentUser.uid,
        createdAt: Date.now(),
        associateCompany: id,
        email: email,
        avatar: "https://res.cloudinary.com/united-app/image/upload/v1686929983/avatars/avatar2_detyjq.png",
        firstName: firstName,
        lastName: lastName,
        isDisplay: true,
        files: [],
        trainings: [],
        company: companyVal,
        constructor: constructor,
        branch: branch,
        employeeNumber: employeeNumber,
        hospitality: hospitality,
        department: department
      };

      const docRef = await addDoc(collection(database, "employees"), newEmployee);
      const addedEmployee = { ...newEmployee, id: docRef.id };

      addEmployeeToStore(addedEmployee);
      setAddEmployeeView(false);
      toast.success(`עובד חדש ${firstName} ${lastName} התווסף בהצלחה`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const uploadEmployeeFile = async (fileName, fileSource, fileExpDate, empId) => {
    if (fileName !== "" && fileSource) {
      try {
        const employeesRef = doc(database, "employees", empId);
        const storageRef = ref(storage, `files/${fileSource.name}`);
        const uploadTask = uploadBytesResumable(storageRef, fileSource);
  
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(prog); // Show upload progress if required
          },
          (error) => console.log(error),
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              const vdate = new Date(fileExpDate);
  
              const new_file = {
                id: generateRandomId(),
                fileName: fileName,
                fileSource: downloadURL,
                fileExpDate: vdate.getTime(),
              };
  
              // Update Firestore with the new file
              await updateDoc(employeesRef, {
                files: arrayUnion(new_file),
              });
  
              // Update the local state for real-time UI update
              setSelectedEmployee((prev) => ({
                ...prev,
                files: [...prev.files, new_file],
              }));
  
              // Update the store if necessary
              updateEmployeeFileInStore(empId, new_file);
  
              toast.success("קובץ הועלה בהצלחה");
            } catch (error) {
              toast.error(error.message);
            }
          }
        );
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("נא לציין את שם המסך ולצרף את המסמך");
    }
  };
  


  
  const importEmployees = async () => {
    if (data && data.length > 0) {
      try {
        const departmentsSet = new Set();
  
        // Collect unique departments from the data
        for (const row of data) {
          const departmentName = row["מחלקה"] ? row["מחלקה"] : "";
          if (departmentName) {
            departmentsSet.add(departmentName);
          }
  
          const newEmployee = {
            associateId: userPersonalData.associateId,
            createdBy: auth.currentUser.uid,
            createdAt: Date.now(),
            associateCompany: id,
            firstName: row["שם פרטי*"] ? row["שם פרטי*"] : "",
            lastName: row["שם משפחה*"] ? row["שם משפחה*"] : "",
            address: row["כתובת מגורים"] ? row["כתובת מגורים"] : "",
            isDisplay: true,
            avatar: "https://res.cloudinary.com/united-app/image/upload/v1686929983/avatars/avatar2_detyjq.png",
            employeeId: row["תעודת זהות*"] ? row["תעודת זהות*"] : 0,
            email: row["דואר אלקטרוני"] ? row["דואר אלקטרוני"] : "",
            phone: row["טלפון נייד"] ? row["טלפון נייד"] : "",
            position: row["תפקיד בחברה*"] ? row["תפקיד בחברה*"] : "",
            company: departmentName,
            constructor: row["שם קבלן"] ? row["שם קבלן"] : "",
            branch: row["שם סניף"] ? row["שם סניף"] : "",
            employeeNumber: row["מספר עובד"] ? row["מספר עובד"] : "",
            hospitality: row["קופת חולים"] ? row["קופת חולים"] : "",
            files: [],
            trainings: [],
          };
  
          // Add employee to Firestore and get the document ID
          const docRef = await addDoc(collection(database, "employees"), newEmployee);
          const employeeWithId = { ...newEmployee, id: docRef.id };
  
          // Add the employee with ID to the Zustand store
          addEmployeeToStore(employeeWithId);
        }
  
        // Fetch existing departments for the company
        const companyRef = doc(database, "companies", id);
        const companySnapshot = await getDoc(companyRef);
        const existingDepartments = companySnapshot.exists() && companySnapshot.data().departments
          ? companySnapshot.data().departments.map((dept) => dept.departmentName)
          : [];
  
        // Filter out departments that already exist in Firestore
        const newDepartments = Array.from(departmentsSet)
          .filter((name) => !existingDepartments.includes(name))
          .map((name) => ({
            departmentName: name,
            empId: generateRandomId(),
            createdBy: auth.currentUser.uid,
            createdAt: Date.now(),
          }));
  
        // Add each new department individually to ensure all are added
        for (const dept of newDepartments) {
          await updateDoc(companyRef, {
            departments: arrayUnion(dept),
          });
        }
  
        // Fetch the updated list of departments from Firestore after all additions
        const updatedCompanySnapshot = await getDoc(companyRef);
        const updatedDepartments = updatedCompanySnapshot.data().departments || [];
  
        // Update Zustand store with the updated list of departments
        useDashboardStore.getState().updateCompanyDepartments(id, updatedDepartments);
  
        // Optionally, update the local state to reflect changes immediately
        setDepartments(updatedDepartments);
  
        // Clear file input and show success message
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        toast.success("עובדים ומחלקות יובאו בהצלחה");
  
      } catch (error) {
        toast.error(error.message);
        console.error("Error importing employees and departments:", error);
      }
    }
  };
  







  function generateRandomId() {
    return Math.random().toString(36).substr(2, 10);
  }

  const updateEmployee = async (
    firstName,
    lastName,
    address,
    position,
    mobile,
    employeeId,
    email,
    avatar,
    empid,
    companyVal,
    branch,
    constructor,
    employeeNumber,
    hospitality,
    department
  ) => {
    try {
      const employeeRef = doc(database, "employees", empid);
  
      const updateEmployeeData = async (avatarUrl = null) => {
        const updatedEmployee = sanitizeEmployeeData({
          id: empid,
          firstName,
          lastName,
          address,
          position,
          phone: mobile,
          employeeId,
          email,
          avatar: avatarUrl || avatar,
          company: companyVal ? companyVal : "",
          branch: branch ? branch : "",
          constructor: constructor ? constructor : "",
          employeeNumber: employeeNumber ? employeeNumber : "",
          hospitality: hospitality ? hospitality : "",
          department: department? department : ""
        });
  
        await updateDoc(employeeRef, updatedEmployee);
        updateEmployeeInStore(updatedEmployee);
  
        setSelectedEmployee(null);
        toast.success("פרטי העובד התעדכנו בהצלחה");
      };
  
      if (avatar && avatar.name) {
        const storageRef = ref(storage, `files/${avatar.name}`);
        const uploadTask = uploadBytesResumable(storageRef, avatar);
  
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(prog);
          },
          (error) => {
            console.log(error);
            toast.error(error.message);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              await updateEmployeeData(downloadURL);
            } catch (error) {
              toast.error(error.message);
            }
          }
        );
      } else {
        await updateEmployeeData();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const importProps = async () => {
    if (propsData.length > 0) {
      try {
        propsData.forEach(async (row) => {
          const objid = generateRandomId();
          const machineManifacture = row["יצרן"] || "";
          const machineYear = row["שנת ייצור"] || "";
          const machineSerialNumber = row["מספר סידורי"] || "";
          const machineModel = row["דגם/מודל"] || "";
          const machineComments = row["תיאור האביזר"] || "";
          const machineLicenseNumber = row["מספר רישוי"] || "";
          const assetCategory = row["מחלקה"] || "";
          const machineType = row["סוג האביזר"] || "";

          const new_machine = {
            empId: objid,
            createdBy: auth.currentUser.uid,
            createdAt: Date.now(),
            machineManifacture: machineManifacture,
            machineYear: machineYear,
            machineSerialNumber: machineSerialNumber,
            machineModel: machineModel,
            machineComments: machineComments,
            machineLicenseNumber: machineLicenseNumber,
            department: assetCategory,
            machineType: machineType,
          };
          const companyRef = doc(database, "companies", id);
          await updateDoc(companyRef, { machines: arrayUnion(new_machine) });
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        });
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("יש להעלות קובץ אביזרים");
    }
  };

  const addNewMachine = async (
    machineManifacture,
    machineYear,
    machineSerialNumber,
    machineModel,
    machineType,
    machineComments,
    machineLicenseNumber,
    department
  ) => {
    const objid = generateRandomId();

    const existingDepartment = company.departments.find(
      (dept) => dept.departmentName === department
    );

    if (!existingDepartment) {
      const new_department = {
        empId: generateRandomId(),
        createdBy: auth.currentUser.uid,
        createdAt: Date.now(),
        departmentName: department,
      };

      const companyRef = doc(database, "companies", id);
      await updateDoc(companyRef, {
        departments: arrayUnion(new_department),
      });

      // Update Zustand store to include the new department
      useDashboardStore.getState().updateCompanyDepartments(id, new_department);
    }

    try {
      const new_machine = {
        empId: objid,
        createdBy: auth.currentUser.uid,
        createdAt: Date.now(),
        machineManifacture: machineManifacture,
        machineYear: machineYear,
        machineSerialNumber: machineSerialNumber,
        machineModel: machineModel,
        machineType: machineType,
        machineComments: machineComments,
        machineLicenseNumber: machineLicenseNumber,
        department: department,
      };
      const companyRef = doc(database, "companies", id);
      await updateDoc(companyRef, {
        machines: arrayUnion(new_machine),
      }).then(async (res) => {
        setAddMachineView(false);
        toast.success(`אביזר חדש ${machineManifacture} ${machineSerialNumber} התווסף בהצלחה`);
      });
    } catch (error) {
      toast.error(error.message);
    }
  };




  const addNewDepartment = async () => {
    const objid = generateRandomId();
  
    try {
      const new_department = {
        empId: objid,
        createdBy: auth.currentUser.uid,
        createdAt: Date.now(),
        departmentName: departmentName,
      };
  
      const companyRef = doc(database, "companies", id);
      const companySnapshot = await getDoc(companyRef);
  
      if (!companySnapshot.exists()) {
        throw new Error("Company document does not exist");
      }
  
      const companyData = companySnapshot.data();
  
      if (!companyData.departments) {
        await updateDoc(companyRef, { departments: [] });
      }
  
      await updateDoc(companyRef, {
        departments: arrayUnion(new_department),
      });
  
      // Update Zustand and merge the new department with the existing departments
      useDashboardStore.getState().updateCompanyDepartments(id, new_department);
      setDepartments((prevDepartments) => [...prevDepartments, new_department]);
  
      setDepartmentName("");
      toast.success(`מחלקה חדשה ${departmentName} התווספה בהצלחה`);
  
    } catch (error) {
      console.error("Error adding department:", error);
      toast.error(`Error: ${error.message}`);
    }
  };
  
  
  
  

  



  

  const removeMachine = async (machineId) => {
    const isConfirmed = window.confirm("האם למחוק את האביזר לצמיתות?");
    if (isConfirmed) {
      try {
        const companyRef = doc(database, "companies", id);
        await updateDoc(companyRef, {
          machines: arrayRemove(machineId),
        }).then((res) => {
          toast.success("ההסרה התבצעה בהצלחה");
        });
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const updateMachine = async (
    machineComments,
    machineLicenseNumber,
    machineManifacture,
    machineModel,
    machineSerialNumber,
    machineType,
    machineYear,
    empId,
    department
  ) => {
    try {
      const companyRef = doc(database, "companies", id);
      const docSnapshot = await getDoc(companyRef);
  
      if (docSnapshot.exists()) {
        const dataArray = docSnapshot.data().machines;
  
        const machineIndex = dataArray.findIndex((obj) => obj.empId === empId);
        if (machineIndex === -1) {
          throw new Error("Machine not found");
        }
  
        // Check if the department exists
        const departmentsArray = docSnapshot.data().departments || [];
        const existingDepartment = departmentsArray.find(
          (dept) => dept.departmentName === department
        );
  
        // If the department does not exist, add it
        if (!existingDepartment) {
          const newDepartment = {
            empId: generateRandomId(),
            createdBy: auth.currentUser.uid,
            createdAt: Date.now(),
            departmentName: department,
          };
  
          // Add the new department to Firestore
          await updateDoc(companyRef, {
            departments: arrayUnion(newDepartment),
          });
  
          // Update Zustand store to include the new department
          useDashboardStore.getState().updateCompanyDepartments(id, newDepartment);
        }
  
        // Update the machine details
        dataArray[machineIndex] = {
          ...dataArray[machineIndex],
          machineComments,
          machineLicenseNumber,
          machineManifacture,
          machineModel,
          machineSerialNumber,
          machineType,
          machineYear,
          department,
        };
  
        // Update the machines array in Firestore
        await updateDoc(companyRef, { machines: dataArray });
  
        // Update Zustand store to reflect the changes immediately
        useDashboardStore.getState().updateCompanyMachines(id, dataArray);
      } else {
        throw new Error("Company document not found");
      }
    } catch (error) {
      console.error("Error updating machine:", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    let filteredMachines = machinesOrig;

    if (machineFilter) {
      filteredMachines = filteredMachines.filter((machine) => machine.machineType === machineFilter);
    }
    if (categoryFilter) {
      filteredMachines = filteredMachines.filter((machine) => machine.assetCategory === categoryFilter);
    }

    setMachines(filteredMachines);
  }, [machineFilter, categoryFilter, machinesOrig]);

  const filteredEmployees = employeesArr.filter((employee) => {
    const positionMatch = selectedPosition ? employee.position === selectedPosition : true;
    const companyMatch = selectedCompany ? employee.company === selectedCompany : true;
    const branchMatch = selectedBranch ? employee.branch === selectedBranch : true;
    const constructorMatch = selectedConstruction ? employee.constructor === selectedConstruction : true;
    return positionMatch && companyMatch && branchMatch && constructorMatch;
  });

  const filteredMachines = machines.filter((machine) => {
    const departmentMatch = selectedDepartment ? machine.department === selectedDepartment : true;
    const machineTypeMatch = selectedMachineType ? machine.machineType === selectedMachineType : true;
    return departmentMatch && machineTypeMatch;
  });

  const removeDepartment = async(departmentId) => {
    const isConfirmed = window.confirm("האם למחוק את המחלקה לצמיתות?");
    if (isConfirmed) {
      try {
        const companyRef = doc(database, "companies", id);
        await updateDoc(companyRef, {
          departments: arrayRemove(departmentId),
        }).then((res) => {
          useDashboardStore.getState().removeCompanyDepartment(id, departmentId);
          toast.success("ההסרה התבצעה בהצלחה");
        });
      } catch (error) {
        toast.error(error.message);
      }
    }
  }

  const updateDepartment = async(empId,departmentName) => {
    const companyRef = doc(database, "companies", id);
    getDoc(companyRef).then(async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const dataArray = docSnapshot.data().departments;
        const updatedObjectIndex = dataArray.find((obj) => obj.empId === empId);
        updatedObjectIndex.departmentName = departmentName;
        updateDoc(companyRef, { departments: dataArray })
          .then((res) => {
            useDashboardStore.getState().updateCompanyDepartment(id, {
              empId,
              departmentName,
            });
          })
          .catch((updateError) => {
            console.error("Error updating document:", updateError);
          });
      }
    });
  }

  const exportEmployeesToExcel = (filteredEmployees) => {
    
    const timestamp = new Date().getTime();
    const fileName = `employees_${timestamp}.xlsx`;

    const headers = [
      { header: 'שם פרטי', key: 'firstName', width: 10 },
      { header: 'שם משפחה', key: 'lastName', width: 15 },
      { header: 'תעודת זהות', key: 'employeeId', width: 15 },
      { header: 'תפקיד בחברה', key: 'position', width: 15 },
      { header: 'טלפון נייד', key: 'phone', width: 20 },
      { header: 'דואר אלקטרוני', key: 'email', width: 20 },
      { header: 'כתובת מגורים', key: 'address', width: 40 },
      { header: 'שם סניף', key: 'branch', width: 40 },
      { header: 'מחלקה', key: 'company', width: 40 },
      { header: 'שם קבלן', key: 'constructor', width: 40 },
      { header: 'מספר עובד', key: 'employeeNumber', width: 40 },
      { header: 'קופת חולים', key: 'hospitality', width: 40 },
    ];

    const data = filteredEmployees.map(emp => ({
      firstName: emp.firstName,
      lastName: emp.lastName,
      employeeId: emp.employeeId,
      position: emp.position,
      phone: emp.phone,
      email: emp.email,
      address: emp.address,
      branch: emp.branch,
      company: emp.company,
      constructor: emp.constructor,
      employeeNumber: emp.employeeNumber,
      hospitality: emp.hospitality,
    }));

    const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
    headers.forEach((header, index) => {
        ws[XLSX.utils.encode_cell({ c: index, r: 0 })] = { v: header.header };
    });
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reports");
    XLSX.writeFile(wb, fileName);
  };



  return (
    <>
      <ToastContainer />
      <div className="row heading-container">
        <div className="col-lg-6">
          <h3>ניהול חברה <b>{company.companyName}</b></h3>
        </div>
        <div className="col-lg-6 heading-btn-container">
          {myAccount?.role !== "user" && (
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="הסרת החברה תעביר את רישומי החברה, התסקירים, הדוחות, האביזרים והעובדים לארכיון"
              onClick={handleDelete}
              className="btn btn-danger">מחיקת חברה</button>
          )}
          {showConfirmation && (
            <div style={{ flexDirection: "row", marginTop: 10 }}>
              <p>
                הסרת החברה תעביר את רישומי החברה, התסקירים, הדוחות, האביזרים והעובדים לארכיון
                <br />
                <button className="btn btn-danger" onClick={handleConfirmDelete}>
                  אישור מחיקה
                </button>{" "}
                <button className="btn btn-success" onClick={handleCancelDelete}>
                  ביטול
                </button>
              </p>
            </div>
          )}
        </div>
      </div>


      <div className="row" style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 20 }}>
        <div className="col-lg-4" onClick={() => setCompanyView("edit")}>
          <div className="card" style={{height:140}}>
            <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="db-box-title">פרטי החברה</p>
                <p className="db-box-number222" style={{fontSize:36}}>{company?.companyName}</p>
              </div>
              <div>
                <FcBriefcase size={50} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2" onClick={() => setCompanyView("sites")}>
          <div className="card" style={{height:140}}>
            <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="db-box-title">אתרים</p>
                <p className="db-box-number222">{company?.projects?.length}</p>
                <p className="db-box-title">{myPlan.sites} / {sitesCount}</p>
              </div>
              <div>
                <FcDepartment size={50} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2" onClick={() => setCompanyView("employees")}>
          <div className="card" style={{height:140}}>
            <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="db-box-title">עובדים לחברה</p>
                <p className="db-box-number222">{employeesArr.length}</p>
                <p className="db-box-title">{myPlan.employees} / {employees.length}</p>
              </div>
              <div>
                <FcBusinessman size={50} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2" onClick={() => setCompanyView("machines")}>
          <div className="card" style={{height:140}}>
            <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="db-box-title">אביזרים</p>
                <p className="db-box-number222">{company?.machines ? company?.machines?.length : 0}</p>
              </div>
              <div>
                <FcInTransit size={50} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2" onClick={() => setCompanyView("departments")}>
          <div className="card" style={{height:140}}>
            <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="db-box-title">מחלקות</p>
                <p className="db-box-number222">{company?.departments ? company?.departments?.length : 0}</p>
              </div>
              <div>
                <FcGenealogy size={50} />
              </div>
            </div>
          </div>
        </div>


      </div>
      <div className="row" style={{ padding: 50 }}>
        {companyView === "edit" ? (
          <>
            <div className="col-lg-12">
              <div style={{ alignItems: "center", display: "flex" }}>
                <h5>פרטי החברה</h5>
                <button
                  className="btn btn-light"
                  onClick={() => {
                    setCompanyEditView(!companyEditView);
                  }}
                >
                  <FaRegEdit color="#01A7C2" size={20} />
                </button>
              </div>
              <div style={{ marginTop: 20 }}>
                {isLoading ? (
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    {companyEditView ? (
                      <EditCompany company={company} updateCompany={updateCompany} />
                    ) : (
                      <>
                        <img
                          alt="logo"
                          className="img-thumbnail"
                          src={
                            company.companyLogo
                              ? company.companyLogo
                              : "https://res.cloudinary.com/united-app/image/upload/v1683366002/avatars/avatar_k4ntm3.png"
                          }
                        />
                        <p style={{ marginTop: 20 }}>
                          <b>שם החברה: </b>
                          {company.companyName}
                          <br />
                          <b>טלפון: </b>
                          {company.companyPhone}
                          <br />
                          <b>דואר אלקטרוני: </b>
                          {company.companyEmail}
                          <br />
                          <b>כתובת: </b>
                          {company.companyAddress}, {company.companyCity}
                          <br />
                          <b>מספר ח.פ. </b>
                          {company.companyTaxId}
                          <br />
                          <b>התווספה למערכת בתאריך: </b>
                          {moment(company.createdAt).format("DD/MM/YYYY")}
                        </p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) 
        : companyView === "departments" ? (<>
          <div className="col-lg-12">
            <div style={{ alignItems: "center", display: "flex" }}><h5>מחלקות</h5></div>
          </div>

          <div className="row"
              style={{ backgroundColor: "#ebebeb", padding: 12, borderRadius: 16, marginTop: 16 }}>
              <div className="col-lg-6">

              <input
                type="text"
                placeholder="שם המחלקה"
                className="form-control"
                value={departmentName}
                onChange={(e) => {
                  setDepartmentName(e.target.value);
                }}
              />
                
              </div>
              <div className="col-lg-2">
                <button onClick={addNewDepartment} className="btn btn-success">הוספת מחלקה חדשה</button>
              </div>

              <div className="row" style={{marginTop:12}}>
                <div className="col-lg-12">
                <>
                  {departments.length > 0 ? (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">מספר מזהה</th>
                          <th scope="col">שם המחלקה</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {departments.map((depart) => (
                          <DepartmentRow
                            removeDepartment={removeDepartment}
                            updateDepartment={updateDepartment}
                            depart={depart}
                          />
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>לא קיימות מחלקות לחברה</p>
                  )}
                </>
                </div>
              </div>
            </div>


        </>)
        
        : companyView === "employees" ? (
          <>
            <div className="col-lg-12">
              <div style={{ alignItems: "center", display: "flex" }}><h5>עובדים</h5></div>
              
              
              {
                employees.length < myPlan.employees && (<>
                
                <div className="row" style={{ backgroundColor: "#ebebeb", padding: 12, borderRadius: 16, marginTop: 16 }}>
                
                <div className="col-lg-2">
                  <button style={{ marginTop: 4 }} className="btn btn-success" onClick={() => {setAddEmployeeView(!addEmployeeView);}}><FiPlusCircle color="#ffffff" size={20} /> הוספת עובד חדש</button>
                </div>

                <div className="col-lg-3"><p style={{ marginTop: 12 }}>ייבוא עובדים מקובץ אקסל{" "}<a href="https://firebasestorage.googleapis.com/v0/b/memune-fbd73.appspot.com/o/employees_v4.xlsx?alt=media&token=14ea39f0-d97f-429c-abc2-1a22bee9cdb3">הורדת קובץ לדוגמה</a><br /></p></div>
                <div className="col-lg-3"><input style={{ marginTop: 6 }} ref={fileInputRef} accept=".xlsx, .xls" type="file" onChange={handleFileUpload} className="form-control"/>
                
                <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Header closeButton><Modal.Title>תצוגה מקדימה לקובץ עובדים</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div style={{ overflowX: 'auto' }}>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    {data[0] && Object.keys(data[0]).map((key, index) => (
                                        <th key={index}>{key}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map((val, idx) => (
                                            <td key={idx}>{val}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => setShowModal(false)}>סגירת חלון תצוגה</Button>
                </Modal.Footer>
                </Modal>

                </div>
                <div className="col-lg-2">
                  {
                    isImportEmployeeAllow && <button style={{ marginTop: 4 }} onClick={importEmployees} className="btn btn-success">ייבוא עובדים</button>
                  }
                </div>
                <div className="col-lg-2">

                <button style={{marginRight:12}} onClick={() => {}} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
                <button className="btn btn-success" style={{marginRight:10}} onClick={() => {exportEmployeesToExcel(filteredEmployees)}}><PiMicrosoftExcelLogoFill color="#ffffff" size={30} /></button>

                </div>

              </div>
                </>)
              }

              <div className="row" style={{ marginTop: 12 }}>
                <div className="col-lg-3">
                  <select
                    className="form-control"
                    value={selectedPosition}
                    onChange={(e) => {
                      setSelectedPosition(e.target.value);
                    }}
                  >
                    <option value="">סינון לפי תפקיד...</option>
                    {positionsList.map((position, index) => (
                      <option key={index} value={position}>
                        {position}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3">
                  <select
                    className="form-control"
                    value={selectedCompany}
                    onChange={(e) => {
                      setSelectedCompany(e.target.value);
                    }}>
                    <option value="">סינון לפי מחלקה...</option>
                    {departments.map((department) => (
                      <option key={department.empId} value={department.departmentName}>
                        {department.departmentName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3">
                  <select
                    className="form-control"
                    value={selectedBranch}
                    onChange={(e) => {
                      setSelectedBranch(e.target.value);
                    }}
                  >
                    <option value="">סינון לפי סניף...</option>
                    {branchList.map((branch, index) => (
                      <option key={index} value={branch}>
                        {branch}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3">
                  <select
                    className="form-control"
                    value={selectedConstruction}
                    onChange={(e) => {
                      setSelectedConstructor(e.target.value);
                    }}
                  >
                    <option value="">סינון לפי קבלן...</option>
                    {constructorList.map((constructor, index) => (
                      <option key={index} value={constructor}>
                        {constructor}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                {isLoading ? (
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    {addEmployeeView ? (
                      <AddEmployee
                        departments={departments}
                        addNewEmployee={addNewEmployee} />
                    ) : (
                      <>
                        {selectedEmployee ? (
                          
                          

                          <EmployeeCard

                            departments={departments}
                            updateEmployee={updateEmployee}
                            uploadEmployeeFile={uploadEmployeeFile}
                            selectedEmployee={selectedEmployee}
                            setSelectedEmployee={setSelectedEmployee}
                          />
                        ) : (
                          <>
                            {filteredEmployees.length > 0 ? (
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>
                                      <input
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                      />
                                    </th>
                                    <th scope="col">תעודת זהות</th>
                                    <th scope="col">מס׳ עובד</th>
                                    <th scope="col">שם מלא</th>
                                    <th scope="col">תפקיד</th>
                                    <th scope="col">מחלקה</th>
                                    <th scope="col">טלפון</th>
                                    <th scope="col">סניף</th>
                                    <th scope="col">קבלן</th>
                                    <th scope="col">קופ״ח</th>
                                    <th scope="col">קבצים</th>
                                    <th scope="col">ועדות</th>
                                    <th scope="col">עריכה</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredEmployees.map((emp) => (
                                    <EmployeeSmall
                                      key={emp.id}
                                      employee={emp}
                                      deleteEmployee={deleteEmployee}
                                      setSelectedEmployee={setSelectedEmployee}
                                      isSelected={selectedEmployees.includes(emp.id)}
                                      handleSelectEmployee={handleSelectEmployee}
                                    />
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <p>לא קיימים עובדים לחברה</p>
                            )}
                            <button className="btn btn-danger" onClick={deleteSelectedEmployees}>מחיקת עובדים נבחרים</button>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        
        ) : companyView === "machines" ? (
          <>
            <div className="row">
              <div className="col-lg-12"><h5>אביזרים, אביזרי הרמה וציוד כללי</h5></div>
            </div>

            <div className="row"
              style={{ backgroundColor: "#ebebeb", padding: 12, borderRadius: 16, marginTop: 16 }}>
              <div className="col-lg-2">
                <button style={{ marginTop: 4 }} className="btn btn-success"
                  onClick={() => {setAddMachineView(!addMachineView)}}>
                  <FiPlusCircle color="#ffffff" size={20} /> הוספת אביזר חדש
                </button>
              </div>
              <div className="col-lg-4">
                <p>
                  ייבוא אביזרים מקובץ אקסל{" "}
                  <a href="https://firebasestorage.googleapis.com/v0/b/memune-fbd73.appspot.com/o/assets.xlsx?alt=media&token=6be79ef0-02c7-4674-8982-34c0e0ef7127">
                    הורדת קובץ לדוגמה
                  </a>
                  <br />
                  <b>חובה לבחור סוג אביזר וקטגוריה מהרשימות למעלה לפני ההוספה</b>
                </p>
              </div>
              <div className="col-lg-4">
                <input
                  style={{ marginTop: 6 }}
                  ref={fileInputRef}
                  accept=".xlsx, .xls"
                  type="file"
                  onChange={handleFileUploadForProps}
                  className="form-control"
                />


        <Modal show={showPropsModal} onHide={() => setShowPropsModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>חלון תצוגה מקדימה לקובץ אביזרים</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ overflowX: 'auto' }}>
            <table className='table table-striped'>
              <thead>
                <tr>
                  {propsData[0] && Object.keys(propsData[0]).map((key, index) => (
                    <th key={index}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {propsData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((val, idx) => (
                      <td key={idx}>{val}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => setShowPropsModal(false)}>סגירת חלון תצוגה מקדימה</Button>
        </Modal.Footer>
      </Modal>


              </div>
              <div className="col-lg-2">
                <button style={{ marginTop: 4 }} onClick={importProps} className="btn btn-success">
                  ייבוא אביזרים
                </button>
              </div>
            </div>



            <div className="row" style={{ marginTop: 12 }}>
              <div className="col-lg-3">
                <select
                  value={selectedDepartment}
                  onChange={(e) => {
                    setSelectedDepartment(e.target.value);
                  }} className="form-control">
                  <option value="">בחירת מחלקה...</option>
                  {departments.map((department) => (
                    <option value={department.departmentName} key={department.empId}>
                      {department.departmentName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-3">
                <select
                  value={selectedMachineType}
                  onChange={(e) => {
                    setSelectedMachineType(e.target.value);
                  }} className="form-control">
                  <option value="">בחירת סוג אביזר...</option>
                  {periodicList?.map((item) => (
                    <option value={item.trackingName} key={item.id}>
                      {item.trackingName}
                    </option>
                  ))}
                </select>
              </div>
            </div>


            <div style={{ marginTop: 20 }}>
              {addMachineView ? (
                <AddMachine
                  departments={departments}
                  periodicList={periodicList}
                  assetsCategoriesList={assetsCategoriesList}
                  addNewMachine={addNewMachine}
                />
              ) : (
                <>
                  {machines.length > 0 ? (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">סוג אביזר</th>
                          <th scope="col">יצרן</th>
                          <th scope="col">דגם</th>
                          <th scope="col">מס׳ רישוי</th>
                          <th scope="col">מס׳ סידורי</th>
                          <th scope="col">שנת ייצור</th>
                          <th scope="col">הערות</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredMachines.map((machine) => (
                          <MachineRow
                            departments={departments}
                            periodicList={periodicList}
                            removeMachine={removeMachine}
                            updateMachine={updateMachine}
                            machine={machine}
                          />
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>לא קיימים אביזרים לחברה</p>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <div className="col-lg-12">
            <div style={{ alignItems: "center", display: "flex" }}>
              <h5>אתרים</h5>
             
              {
                sitesCount < myPlan.sites && (<>
                <button
                className="btn btn-light"
                onClick={() => {
                  setAddProjectView(!addProjectView);
                }}>
                <FiPlusCircle color="#01A7C2" size={20} />
              </button>
                </>)
              }


            </div>
            <div style={{ marginTop: 20 }}>
              {isLoading ? (
                <div className="spinner-border text-info" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <>
                  {addProjectView ? (
                    <AddProject addNewProjectClick={addNewProject} />
                  ) : (
                    <>
                      {selectedProject ? (
                        <ProjectCard
                          updateProject={updateProject}
                          setSelectedProject={setSelectedProject}
                          selectedProject={selectedProject}
                          removeProject={removeProject}
                        />
                      ) : (
                        <>
                          {company?.projects?.length > 0 ? (
                            <>
                              {company.projects.map((project) => (
                                <SiteSmall key={project.siteId} project={project} setSelectedProject={setSelectedProject} />
                              ))}
                            </>
                          ) : (
                            <p>לא קיימים אתרים לחברה</p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CompanyEdit;
