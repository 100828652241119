import React from 'react'
import { auth, database } from "../../services/firebase-config";
import { Link, useNavigate } from "react-router-dom";

const TopBarnav = () => {

  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("profile");
    auth.signOut();
    navigate("/");
  }

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/dashboard">
          <img src='/images/logo_black.png' style={{width:40}} />
        </a>

        <button className="navbar-toggler"type="button"data-bs-toggle="collapse"data-bs-target="#navbarSupportedContent"aria-controls="navbarSupportedContent"aria-expanded="false"aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">

            <li className="nav-item"><Link to='/dashboard' className="nav-link">ראשי</Link></li>
            <li className="nav-item"><Link to='/profile' className="nav-link">פרופיל</Link></li>
            <li className="nav-item"><Link to='/mycompany' className="nav-link">החברה שלי</Link></li>


            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">תפריט</a>
              
              <ul className="dropdown-menu">
                <li><Link to='/notifications' className="nav-link">התראות</Link></li>
                <li><Link to='/logs' className="nav-link">פעולות במערכת</Link></li>
                <li><hr className="dropdown-divider" /></li>
                <li><button onClick={logout} className='btn btn-outline-danger'>יציאה מהמערכת</button></li>
              </ul>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  )
}

export default TopBarnav