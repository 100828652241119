import React, { useEffect, useState, useRef } from "react";
import { database, storage } from "../../services/firebase-config";
import { collection, addDoc,getDoc, doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";
import UserCard from "../../components/UserCard";
import UserCardBig from "../../components/UserCardBig";
import { Link } from "react-router-dom";
import useDashboardStore from "../../store/useDashboardStore";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { FaRegFilePdf } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import moment from "moment";

const Users = (props) => {
  const {
    userPersonalData,
    myCompany,
    companies,
    updateUserInStore,
    addUserToStore,
    lockUnlockUserInStore,
    removeUserInStore,
    updateUserProjectRoles,
    accounts,
    myPlan
  } = useDashboardStore();
  const [showModal, setShowModal] = useState(false);
  const exportRef = React.useRef();
  const [isLoading, setIsLoading] = useState(false);


  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);


  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [allUsersOrig, setAllUsersOrig] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [progress, setProgress] = useState(0);
  const modalRef = useRef(null);

  const lockUnlockUser = async (isUserLock, id) => {
    try {
        const accountRef = doc(database, "accounts", id);
        await updateDoc(accountRef, { isUserLock });
        const docSnap = await getDoc(accountRef);
        if (docSnap.exists()) {
            const updatedUser = docSnap.data();
            updatedUser.id = id;
            lockUnlockUserInStore(id, isUserLock);
            setSelectedUser(updatedUser);
        }
    } catch (error) {
        console.log(error.message);
    }
  };
  const updateUser = async (firstName, lastName, email, phone, role, address, city, id, avatar) => {
  try {
      const accountRef = doc(database, "accounts", id);
      if (avatar) {
          const sotrageRef = ref(storage, `files/${avatar.name}`);
          const uploadTask = uploadBytesResumable(sotrageRef, avatar);
          uploadTask.on(
              "state_changed",
              (snapshot) => {
                  const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                  setProgress(prog);
              },
              (error) => console.log(error),
              () => {
                  getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                      await updateDoc(accountRef, {
                          firstName, lastName, email, role, avatar: downloadURL,
                          contact: { address, city, phone }
                      });
                      const docSnap = await getDoc(accountRef);
                      if (docSnap.exists()) {
                          const updatedUser = docSnap.data();
                          updatedUser.id = id;
                          updatedUser.avatar = downloadURL;
                          updateUserInStore(updatedUser);
                          setSelectedUser(updatedUser);
                      }
                  });
              }
          );
      } else {
          await updateDoc(accountRef, {
              firstName, lastName, email, role,
              contact: { address, city, phone }
          });
          const docSnap = await getDoc(accountRef);
          if (docSnap.exists()) {
              const updatedUser = docSnap.data();
              updatedUser.id = id;
              updateUserInStore(updatedUser);
              setSelectedUser(updatedUser);
          }
      }
  } catch (error) {
      console.log(error.message);
  }
  };

  const handleUserSelection = (user) => {
    setSelectedUser(user);
    setSelectedUserId(user.id); // Track the selected user's ID
  };


  useEffect(() => {
    const data = accounts;
    const allAccounts = (users) => data.sort((a, b) => a.lastName.localeCompare(b.lastName));
    setAllUsers(allAccounts);
    setAllUsersOrig(allAccounts);
  }, [accounts]);


  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const handleHide = () => {
        document.body.classList.remove("modal-open");
        document.querySelector(".modal-backdrop.fade.show")?.remove();
      };
      modalElement.addEventListener("hide.bs.modal", handleHide);
      return () => {
        modalElement.removeEventListener("hide.bs.modal", handleHide);
      };
    }
  }, []);

  const createNewAccount = async () => {
    if (firstName && lastName && role && email && phone) {
      try {
        const newUser = {
          uuid: "",
          associateId: userPersonalData.associateId,
          expoPushToken: "",
          role,
          email,
          avatar: "https://res.cloudinary.com/united-app/image/upload/v1683366002/avatars/avatar_k4ntm3.png",
          contact: { phone, address: "", zip: "", city: "", country: "", state: "", signature: "" },
          firstName,
          lastName,
          isDisplay: true,
          projectsRole: [],
          companyName: userPersonalData.companyName,
          createdAt: Date.now(),
          license: 'Associate',
          code: getRandomArbitrary(1000, 9999)
        };

        await addDoc(collection(database, "accounts"), newUser).then(async (docRef) => {
          newUser.id = docRef.id;
          addUserToStore(newUser);

          // if (modalRef.current) {
          //   const modalInstance = Modal.getInstance(modalRef.current);
          //   if (modalInstance) {
          //     modalInstance.hide();
          //   } else {
          //     new Modal(modalRef.current).hide();
          //   }
          // }

          // setTimeout(() => {
          //   document.querySelectorAll(".modal-backdrop.fade.show").forEach((backdrop) => backdrop.remove());
          //   document.body.classList.remove("modal-open");
          // }, 150);

          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setRole("");
        });
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("יש למלא את כל השדות");
    }
  };

  const removeUser = async (id) => {
    try {
      const accountRef = doc(database, "accounts", id);
      await updateDoc(accountRef, { isDisplay: false }).then(() => {
        removeUserInStore(id);
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const addProjectRole = async (user, siteId) => {
    const objid = generateRandomId();
    try {
      const new_role = {
        roleId: objid,
        createdAt: Date.now(),
        role: siteId,
      };
      const accountsRef = doc(database, "accounts", user.id);
      await updateDoc(accountsRef, { projectsRole: arrayUnion(new_role) });

      // Update Zustand store
      updateUserProjectRoles(user.id, [...user.projectsRole, new_role]);

      // Update selectedUser state to trigger re-render
      setSelectedUser((prevUser) => ({
        ...prevUser,
        projectsRole: [...prevUser.projectsRole, new_role],
      }));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const removeProjectRole = async (user, roleId) => {
    try {
      const roleToRemove = user.projectsRole.find(role => role.roleId === roleId);
      if (!roleToRemove) {
        throw new Error("Role not found");
      }
      const accountsRef = doc(database, "accounts", user.id);
      await updateDoc(accountsRef, { projectsRole: arrayRemove(roleToRemove) });

      // Update Zustand store
      const updatedRoles = user.projectsRole.filter(role => role.roleId !== roleId);
      updateUserProjectRoles(user.id, updatedRoles);

      // Update selectedUser state to trigger re-render
      setSelectedUser((prevUser) => ({
        ...prevUser,
        projectsRole: updatedRoles,
      }));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const filterUsers = (usersType) => {
    const orig = allUsersOrig;
    let filter = [];
    if (usersType === 'all') {
      filter = orig;
    }
    if (usersType === 'manager') {
      filter = orig.filter(x => x.role === 'admin');
    }
    if (usersType === 'limited') {
      filter = orig.filter(x => x.role === 'user');
    }
    if (usersType === 'locked') {
      filter = orig.filter(x => x.isUserLock === true);
    }
    if (usersType === 'removed') {
      filter = orig.filter(x => x.isDisplay === false);
    }
    if (usersType === 'approved') {
      filter = orig.filter(x => x.uuid !== "");
    }
    setAllUsers(filter);
  };

  const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 10);
  };

  const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  };


  const handleDownloadPdf = async () => {
    const element = exportRef.current; // Get the element to export
    const canvas = await html2canvas(element, { scale: 1, useCORS: true }); // Generate canvas from DOM
    const imgData = canvas.toDataURL("image/png"); // Convert canvas to data URL

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 190; // A4 width minus some margin
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjusted height based on width
    const pageHeight = pdf.internal.pageSize.height - 20; // A4 height minus some margin for footer

    let heightLeft = imgHeight;
    let position = 0; // Start position for drawing

    // Add the first page with content
    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add subsequent pages if needed
    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft); // Adjust position for next slice
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Add footer on each page
    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8); // Footer font size
      pdf.setTextColor(100); // Footer text color
      pdf.text(
        "This report was generated with Lamemone ERP",
        105,
        pdf.internal.pageSize.height - 10,
        {
          align: "center",
        }
      );
    }

    const timestamp = new Date().getTime();
    pdf.save(`users_${timestamp}.pdf`);
  };

  const handleModal = async () => {
    setShowModal(true); // Open modal to ensure the content is visible for screenshot
  };


  const exportToExcel = (allUsers) => {
    const timestamp = new Date().getTime();
    const fileName = `Users_${timestamp}.xlsx`;

    // Define headers in the order you want them to appear (right to left)
    const headers = [
        { header: 'מספר מזהה', key: 'id', width: 10 },
        { header: 'נוצר בתאריך', key: 'createdAt', width: 15 },
        { header: 'שם פרטי', key: 'firstName', width: 15 },
        { header: 'שם משפחה', key: 'lastName', width: 15 },
        { header: 'קוד פושים', key: 'expoPushToken', width: 20 },
        { header: 'טלפון', key: 'phone', width: 20 },
        { header: 'כתובת', key: 'address', width: 40 },
        { header: 'עיר', key: 'city', width: 20 },
        { header: 'קוד הפעלה', key: 'code', width: 20 },
        { header: 'דואר אלקטרוני', key: 'email', width: 40 },
        { header: 'מוצג', key: 'isDisplay', width: 20 },
        { header: 'חשבון נעול', key: 'isUserLock', width: 20 },
        { header: 'הרשאה', key: 'role', width: 40 },
        { header: 'הרשאות', key: 'projectsRole', width: 40 },
    ];

    const data = allUsers.map(user => ({
      id: user.id,
      createdAt: moment(user.createdAt).format('DD/MM/YYYY'),
      firstName: user.firstName ? user.firstName : '',
      lastName: user.lastName ? user.lastName : '',
      expoPushToken: user.expoPushToken ? user.expoPushToken : '',
      phone: user.contact?.phone ? user.contact?.phone : '', // Using optional chaining in case contact is undefined
      address: user.contact?.address ? user.contact?.address : '',
      city: user.contact?.city ? user.contact?.city : '',
      code: user.code,
      email: user.email,
      isDisplay: user.isDisplay ? 'כן' : 'לא',
      isUserLock: user.isUserLock ? 'כן' : 'לא',
      role: user.role,
      projectsRole: Array.isArray(user.projectsRole)
        ? user.projectsRole.map(sub => {
            const project = companies.find(x => x.id === sub.role);
            return project?.companyName;
          }).join(', ')
        : ''
    }));
    

    // Create a worksheet from the data array, skipping header generation.
    const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });

    // Manually add the headers to the first row of the sheet
    headers.forEach((header, index) => {
        ws[XLSX.utils.encode_cell({ c: index, r: 0 })] = { v: header.header };
    });

    // Append the data starting from the second row
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reports");
    XLSX.writeFile(wb, fileName);
};



  return (
    <>
      <ToastContainer />

      <div
        ref={modalRef}
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">הוספת משתמש חדש למערכת</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <label className="form-label">רמת הרשאה</label>
                  <select value={role} onChange={(e) => { setRole(e.target.value); }} className="form-control">
                    <option value="">בחר רמת הרשאה מהרשימה...</option>
                    <option value="admin">מנהל ראשי</option>
                    <option value="manager">מנהל מערכת</option>
                    <option value="user">משתמש גישה מוגבלת</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label">שם פרטי</label>
                  <input
                    type="text"
                    className="form-control"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label">שם משפחה</label>
                  <input
                    type="text"
                    className="form-control"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label">דואר אלקטרוני</label>
                  <input
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label">טלפון</label>
                  <input
                    type="text"
                    className="form-control"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                סגירה
              </button>
              <button
                onClick={createNewAccount}
                type="button"
                className="btn btn-success"
              >
                הוספת משתמש חדש
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row heading-container">
        <div className="col-lg-9"><h3>ניהול משתמשים במערכת</h3></div>
        <div className="col-lg-3 heading-btn-container">
          {
            allUsers.length < myPlan.users && <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-warning">הוספת משתמש חדש</button>
          }
          {
            myPlan.actions && <>
            <button style={{marginRight:12}} onClick={handleModal} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
            <button className="btn btn-success btn-lg" style={{marginRight:10}} onClick={() => {exportToExcel(allUsers)}}><PiMicrosoftExcelLogoFill color="#ffffff" size={30} /></button>
            </>
          }
        </div>
      </div>

      <div className='row' style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 20 }}>
        <div className='col-lg-2'>
          <Link onClick={() => { filterUsers("all") }} style={{ textDecoration: 'none' }}>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>משתמשים רשומים</p>
                  <p className='db-box-number222'>{myPlan.users} / {allUsers.length}</p>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-lg-2'>
          <Link onClick={() => { filterUsers("manager") }} style={{ textDecoration: 'none' }}>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>הרשאת מנהל</p>
                  <p className='db-box-number222'>{allUsers.filter(x => x.role === 'admin').length}</p>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-lg-2'>
          <Link onClick={() => { filterUsers("limited") }} style={{ textDecoration: 'none' }}>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>הרשאה מוגבלת</p>
                  <p className='db-box-number222'>{allUsers.filter(x => x.role === 'user').length}</p>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-lg-2'>
          <Link onClick={() => { filterUsers("locked") }} style={{ textDecoration: 'none' }}>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>משתמשים נעולים</p>
                  <p className='db-box-number222'>{allUsers.filter(x => x.isUserLock === true).length}</p>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-lg-2'>
          <Link onClick={() => { filterUsers("removed") }} style={{ textDecoration: 'none' }}>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>משתמשים שהוסרו</p>
                  <p className='db-box-number222'>{allUsers.filter(x => x.isDisplay === false).length}</p>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-lg-2'>
          <Link onClick={() => { filterUsers("approved") }} style={{ textDecoration: 'none' }}>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>הקימו חשבון</p>
                  <p className='db-box-number222'>{allUsers.filter(x => x.uuid !== "").length}</p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-6">
          {isLoading ? (
            <div className="spinner-border text-info" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div style={{ height: 600, overflowY: "scroll" }}>
            {allUsers.length > 0 &&
              allUsers.map((user) => (
                <UserCard
                  key={user.id}
                  user={user}
                  setSelectedUser={handleUserSelection}
                  isSelected={selectedUserId === user.id} // Pass whether this user is selected
                />
              ))}
          </div>
          )}
        </div>
        <div className="col-lg-6">
        {selectedUser && (
          <UserCardBig
            lockUnlockUser={lockUnlockUser}
            updateUser={updateUser}
            removeProjectRole={removeProjectRole}
            selectedUser={selectedUser}
            addProjectRole={addProjectRole}
            allCompanies={companies}
            removeUser={removeUser}
          />
        )}
      </div>
      </div>


      <Modal
        show={showModal}
        dialogClassName="custom-modal"
        onHide={() => setShowModal(false)}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" ref={exportRef} style={{ padding: 50 }}>
            
            <div className="row">
              <div className="col-lg-12">
                {myCompany && myCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-1">
                        <img
                          src={myCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-11">
                        <p className="companyInfo">
                          <b>{myCompany.companyName}</b>
                          <br />
                          {myCompany.companyAddress}, {myCompany.companyCity}
                          <br />
                          ח.פ. {myCompany.companyTaxId}
                          <br />
                          {myCompany.companyEmail} | {myCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 50, marginBottom:20 }}>
              <div className="col-lg-12">
                <h2>משתמשים במערכת</h2>
              </div>
            </div>




        <div className='row' style={{marginBottom:30}}>
        <div className='col-lg-2'>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>משתמשים רשומים</p>
                  <p className='db-box-number222'>{myPlan.users} / {allUsers.length}</p>
                </div>
              </div>
            </div>
        </div>

        <div className='col-lg-2'>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>הרשאת מנהל</p>
                  <p className='db-box-number222'>{allUsers.filter(x => x.role === 'admin').length}</p>
                </div>
              </div>
            </div>
        </div>

        <div className='col-lg-2'>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>הרשאה מוגבלת</p>
                  <p className='db-box-number222'>{allUsers.filter(x => x.role === 'user').length}</p>
                </div>
              </div>
            </div>
        </div>

        <div className='col-lg-2'>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>משתמשים נעולים</p>
                  <p className='db-box-number222'>{allUsers.filter(x => x.isUserLock === true).length}</p>
                </div>
              </div>
            </div>
        </div>

        <div className='col-lg-2'>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>משתמשים שהוסרו</p>
                  <p className='db-box-number222'>{allUsers.filter(x => x.isDisplay === false).length}</p>
                </div>
              </div>
            </div>
        </div>

        <div className='col-lg-2'>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>הקימו חשבון</p>
                  <p className='db-box-number222'>{allUsers.filter(x => x.uuid !== "").length}</p>
                </div>
              </div>
            </div>
        </div>
      </div>







      <div className='row'>
        <div className="col-lg-12">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">שם פרטי</th>
                    <th scope="col">שם משפחה</th>
                    <th scope="col">הרשאה</th>
                    <th scope="col">טלפון</th>
                    <th scope="col">כתובת</th>
                    <th scope="col">עיר</th>
                    <th scope="col">דואר אלקטרוני</th>
                    <th scope="col">הרשאות</th>
                    <th scope="col">נעול</th>
                    <th scope="col">מוצג</th>
                  </tr>
                </thead>
                <tbody>

                  {
                  allUsers.length > 0 && allUsers.map((user) => (
                    <tr>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.role}</td>
                      <td>{user.contact.phone}</td>
                      <td>{user.contact.address}</td>
                      <td>{user.contact.city}</td>
                      <td>{user.email}</td>
                      <td>{
                        Array.isArray(user.projectsRole)
                        ? user.projectsRole.map(sub => {
                            const project = companies.find(x => x.id === sub.role);
                            return project?.companyName;
                          }).join(', ')
                        : ''
                        }</td>
                      <td>{user.isUserLock ? 'כן' : 'לא'}</td>
                      <td>{user.isDisplay ? 'כן' : 'לא'}</td>
                    </tr>
                ))}
              </tbody>
              
              </table>
                </div>
                </div>

            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleDownloadPdf}
            className="btn btn-success btn-lg">שמירת קובץ</button>
        </Modal.Footer>
      </Modal>


    </>
  );
};

export default Users;
