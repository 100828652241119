import React, { useEffect, useState, useRef } from "react";
import { auth, database, storage } from "../../services/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import Company from "../../components/Company";
import useDashboardStore from "../../store/useDashboardStore";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { FaRegFilePdf } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import moment from "moment";

const Companies = () => {
  const {
    userPersonalData,
    companies,
    addCompanyToStore,
    employees,
    myCompany,
    myPlan
  } = useDashboardStore();

  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyTaxId, setCompanyTaxId] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [companiesArr, setCompaniesArr] = useState([])
  const [showModal, setShowModal] = useState(false);
  const exportRef = React.useRef();


  useEffect(() => {

    if (userPersonalData.role === "user" && userPersonalData.projectsRole) {
      if (userPersonalData.projectsRole.length > 0) {
        const rolesSet = new Set(
          userPersonalData.projectsRole.map((roleItem) => roleItem.role)
        );
        const filteredCompanies = companies
        .filter((company) => rolesSet.has(company.id))
        .sort((a, b) => a.companyName.localeCompare(b.companyName));

        setCompaniesArr(filteredCompanies);
      }
    } else if (userPersonalData.role === "admin" || userPersonalData.role === "superadmin") {
      const filteredCompanies = companies
        .sort((a, b) => a.companyName.localeCompare(b.companyName));
        setCompaniesArr(filteredCompanies);
      }
  }, [companies,userPersonalData]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const handleHide = () => {
        document.body.classList.remove("modal-open");
        document.querySelector(".modal-backdrop.fade.show")?.remove();
      };
      modalElement.addEventListener("hide.bs.modal", handleHide);
      return () => {
        modalElement.removeEventListener("hide.bs.modal", handleHide);
      };
    }
  }, []);

  const addNewCompany = async () => {
    if (companyName !== "") {
      let newCompany = {};
      try {
        if (companyLogo) {
          const storageRef = ref(storage, `files/${companyLogo.name}`);
          const uploadTask = uploadBytesResumable(storageRef, companyLogo);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const prog = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(prog);
            },
            (error) => console.log(error),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(
                async (downloadURL) => {
                  newCompany = {
                    createdBy: auth.currentUser.uid,
                    createdAt: Date.now(),
                    companyName: companyName,
                    companyTaxId: companyTaxId,
                    companyCity: companyCity,
                    companyLogo: downloadURL,
                    companyAddress: companyAddress,
                    companyEmail: companyEmail,
                    companyPhone: companyPhone,
                    associateId: userPersonalData.associateId,
                    employees: [],
                    projects: [],
                    machines: [],
                    departments: []
                  };
                  const docRef = await addDoc(collection(database, "companies"), newCompany);
                  newCompany.id = docRef.id;
                  addCompanyToStore(newCompany);
                }
              );
            }
          );
        } else {
          newCompany = {
            createdBy: auth.currentUser.uid,
            createdAt: Date.now(),
            companyName: companyName,
            companyTaxId: companyTaxId,
            companyCity: companyCity,
            companyLogo: "",
            companyAddress: companyAddress,
            companyEmail: companyEmail,
            companyPhone: companyPhone,
            associateId: userPersonalData.associateId,
            isArchive: false,
            employees: [],
            projects: [],
            machines: [],
            departments: []
          };
          const docRef = await addDoc(collection(database, "companies"), newCompany);
          newCompany.id = docRef.id;
          addCompanyToStore(newCompany); // Update Zustand store
        }

        setCompanyAddress("");
        setCompanyCity("");
        setCompanyEmail("");
        setCompanyLogo(null);
        setCompanyName("");
        setCompanyPhone("");
        setCompanyTaxId("");
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } else {
      toast.error("נא למלא את שם החברה");
    }
  };

  const filteredCompanies = searchTerm
    ? companiesArr.filter(company =>
        company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : companiesArr;

  const sortedAndFilteredCompanies = filteredCompanies.sort((a, b) => {
    if (a.isArchive && !b.isArchive) {
      return 1;
    } else if (!a.isArchive && b.isArchive) {
      return -1;
    }
    return a.companyName.localeCompare(b.companyName);
  });







  const handleDownloadPdf = async () => {
    const element = exportRef.current;
    const canvas = await html2canvas(element, { scale: 1, useCORS: true });
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 190;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const pageHeight = pdf.internal.pageSize.height - 20;

    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft);
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8);
      pdf.setTextColor(100);
      pdf.text(
        "This report was generated with Lamemone ERP",
        105,
        pdf.internal.pageSize.height - 10,
        {
          align: "center",
        }
      );
    }

    const timestamp = new Date().getTime();
    pdf.save(`companies_${timestamp}.pdf`);
  };

  const handleModal = async () => {
    setShowModal(true);
  };


  const exportToExcel = (sortedAndFilteredCompanies) => {
    const timestamp = new Date().getTime();
    const fileName = `Companies_${timestamp}.xlsx`;

    const headers = [
        { header: 'נוצר בתאריך', key: 'createdAt', width: 15 },
        { header: 'חפ', key: 'companyTaxId', width: 15 },
        { header: 'שם החברה', key: 'companyName', width: 15 },
        { header: 'עובדים', key: 'employees', width: 20 },
        { header: 'אתרים', key: 'projects', width: 20 },
        { header: 'קטגוריות', key: 'departments', width: 40 },
        { header: 'אביזרים', key: 'machines', width: 20 },
        { header: 'דואר אלקטרוני', key: 'companyEmail', width: 40 },
        { header: 'כתובת', key: 'companyAddress', width: 20 },
        { header: 'טלפון', key: 'companyPhone', width: 20 },
        { header: 'פעילה', key: 'isArchive', width: 40 },
    ];

    const data = sortedAndFilteredCompanies.map(user => ({
      createdAt: moment(user.createdAt).format('DD/MM/YYYY'),
      companyTaxId: user.companyTaxId ? user.companyTaxId : '',
      companyName: user.companyName ? user.companyName : '',
      employees: employees.filter(x => x.associateCompany === user.id).length,
      projects: user.projects ? user.projects.length : 0,
      departments: user.departments ? user.departments.length : 0,
      machines: user.machines ? user.machines.length : 0,
      companyEmail: user.companyEmail ? user.companyEmail : '',
      companyAddress: user.companyAddress ? user.companyAddress : '',
      companyPhone: user.companyPhone ? user.companyPhone: '',
      isArchive: user.isArchive ? 'לא' : 'כן',
    }));
    
    const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
    headers.forEach((header, index) => {
        ws[XLSX.utils.encode_cell({ c: index, r: 0 })] = { v: header.header };
    });
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reports");
    XLSX.writeFile(wb, fileName);
};

const fileInputRef = useRef(null);
  const triggerFileInput = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };



  return (
    <>
      <ToastContainer />
      <div
        ref={modalRef}
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                הוספת חברה חדשה
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label className="form-label">שם החברה</label>
              <input
                type="text"
                className="form-control"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />

              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label">דואר אלקטרוני</label>
                  <input
                    type="text"
                    className="form-control"
                    value={companyEmail}
                    onChange={(e) => {
                      setCompanyEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label">העלאת לוגו חברה</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => {
                      setCompanyLogo(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label">כתובת מלאה</label>
                  <input
                    type="text"
                    className="form-control"
                    value={companyAddress}
                    onChange={(e) => {
                      setCompanyAddress(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label">עיר</label>
                  <input
                    type="text"
                    className="form-control"
                    value={companyCity}
                    onChange={(e) => {
                      setCompanyCity(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label">מספר ע.מ / ח״פ</label>
                  <input
                    type="text"
                    className="form-control"
                    value={companyTaxId}
                    onChange={(e) => {
                      setCompanyTaxId(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label">טלפון</label>
                  <input
                    type="text"
                    className="form-control"
                    value={companyPhone}
                    onChange={(e) => {
                      setCompanyPhone(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                סגירה
              </button>
              <button
                onClick={addNewCompany}
                type="button"
                className="btn btn-success">הוספת חברה חדשה</button>
            </div>
          </div>
        </div>
      </div>

      <div className="row heading-container">
        <div className="col-lg-9">
          <h3>ניהול חברות</h3>
        </div>
        <div className="col-lg-3 heading-btn-container">
          {userPersonalData?.role !== 'user' && (
            <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-success btn-lg">הוספת חברה חדשה</button>
          )}
          {
            myPlan.actions && <>
            <button style={{marginRight:12}} onClick={handleModal} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
            <button className="btn btn-success btn-lg" style={{marginRight:10}} onClick={() => {exportToExcel(sortedAndFilteredCompanies)}}><PiMicrosoftExcelLogoFill color="#ffffff" size={30} /></button>
            </>
          }
        </div>
      </div>

      <div className="row" style={{ paddingTop: 20, paddingBottom: 0, paddingRight: 26, paddingLeft: 50 }}>
        <div className="col-lg-12">
          <input
            className="form-control"
            type="text"
            placeholder="חיפוש חברה לפי שם..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="row" style={{ paddingTop: 10, paddingBottom: 50, paddingRight: 50, paddingLeft: 50 }}>
        {isLoading ? (
          <div className="spinner-border text-info" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            {sortedAndFilteredCompanies.length > 0 ? (
              sortedAndFilteredCompanies.map((company) => (
                <Company key={company.id} company={company} employees={employees} />
              ))
            ) : (
              <p>לא קיימות חברות שמורות במערכת</p>
            )}
          </>
        )}
      </div>



      <Modal
        show={showModal}
        dialogClassName="custom-modal"
        onHide={() => setShowModal(false)}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" ref={exportRef} style={{ padding: 50 }}>
            
            <div className="row">
              <div className="col-lg-12">
                {myCompany && myCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-1">
                        <img
                          src={myCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-11">
                        <p className="companyInfo">
                          <b>{myCompany.companyName}</b>
                          <br />
                          {myCompany.companyAddress}, {myCompany.companyCity}
                          <br />
                          ח.פ. {myCompany.companyTaxId}
                          <br />
                          {myCompany.companyEmail} | {myCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 50, marginBottom:20 }}>
              <div className="col-lg-12">
                <h2>חברות</h2>
              </div>
            </div>




        <div className='row' style={{marginBottom:30}}>
        <div className='col-lg-4'>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>חברות</p>
                  <p className='db-box-number222'>{myPlan.companies} / {sortedAndFilteredCompanies.length}</p>
                </div>
              </div>
            </div>
        </div>

        <div className='col-lg-4'>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>חברות פעילות</p>
                  <p className='db-box-number222'>{sortedAndFilteredCompanies.filter(x => x.isArchive !== true).length}</p>
                </div>
              </div>
            </div>
        </div>

        <div className='col-lg-4'>
            <div className="card">
              <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='db-box-title'>חברות בארכיון</p>
                  <p className='db-box-number222'>{sortedAndFilteredCompanies.filter(x => x.isArchive === true).length}</p>
                </div>
              </div>
            </div>
        </div>
      </div>







      <div className='row'>
        <div className="col-lg-12">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">תאריך הקמה</th>
                    <th scope="col">חפ</th>
                    <th scope="col">שם החברה</th>
                    <th scope="col">עובדים</th>
                    <th scope="col">אתרים</th>
                    <th scope="col">קטגוריות</th>
                    <th scope="col">אביזרים</th>
                    <th scope="col">דואר אלקטרוני</th>
                    <th scope="col">כתובת</th>
                    <th scope="col">טלפון</th>
                    <th scope="col">פעילה</th>
                  </tr>
                </thead>
                <tbody>

                  {
                  sortedAndFilteredCompanies.length > 0 && sortedAndFilteredCompanies.map((company) => (
                    <tr>
                      <td>{moment(company.createdAt).format('DD/MM/YYYY')}</td>
                      <td>{company.companyTaxId}</td>
                      <td>{company.companyName}</td>
                      <td>{employees.filter(x => x.associateCompany === company.id).length}</td>
                      <td>{company.projects?.length}</td>
                      <td>{company.departments?.length}</td>
                      <td>{company.machines?.length}</td>
                      <td>{company.companyEmail}</td>
                      <td>{company.companyAddress}, {company.companyCity}</td>
                      <td>{company.companyPhone}</td>
                      <td>{company.isArchive ? 'לא' : 'כן'}</td>
                    </tr>
                ))}
              </tbody>
              
              </table>
                </div>
                </div>

            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleDownloadPdf}
            className="btn btn-success btn-lg">שמירת קובץ</button>
        </Modal.Footer>
      </Modal>



    </>





  );
};

export default Companies;
