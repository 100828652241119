import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";

const UserCard = ({ user, setSelectedUser, isSelected }) => {
  const cardStyle = {
    marginBottom: 20,
    cursor: "pointer",
    backgroundColor: isSelected ? "#e0f7fa" : "transparent", // Highlight if selected
    border: isSelected ? "1px solid #01A7C2" : "none", // Optional: Add a border for emphasis
  };

  return (
    <div
      className="row"
      style={cardStyle}
      onClick={() => {
        setSelectedUser(user);
      }}
    >
      <div className="col-lg-2">
        <img
          src={user.avatar}
          alt={user.firstName}
          className="rounded mx-auto d-block"
        />
      </div>
      <div className="col-lg-9">
        <h6>
          {user.lastName} {user.firstName} | {user.code}{" "}
          {!user.isDisplay && (
            <span style={{ color: "red" }}>משתמש הוסר מהמערכת</span>
          )}
        </h6>
        <p>
          <b>רמת הרשאה: </b>
          {user.role} | <b>מצב חשבון: </b>{" "}
          {user.isUserLock ? "חשבון נעול" : "חשבון פעיל"}
        </p>
        <p>
          <b>דואר אלקטרוני: </b>
          {user.email}, <b>טלפון: </b>
          {user.contact.phone}
        </p>
      </div>
      <div
        className="col-lg-1"
        style={{ display: "flex", alignItems: "center" }}
      >
        <MdArrowBackIosNew color="#01A7C2" size={20} />
      </div>
    </div>
  );
};

export default UserCard;
