import React, { useState } from 'react'
import { MdDeleteOutline,MdEdit } from "react-icons/md";
const SafetyReportSubItemScore = (props) => {


    const [isEditView, setIsEditView] = useState(false);

  return (
    <div className='row safety-item-row'>
        <div className='col-lg-6'>
            <p>{props.score.safetyReportSubItemTitleName}</p>
        </div>
        <div className='col-lg-2'>
            <p className='site-name'>{props.score.safetyReportSubItemTitleScore}</p>
        </div>

        <div className='col-lg-4'>
            
            <button style={{marginLeft:5}} onClick={() => {setIsEditView(!isEditView)}} className='btn btn-success btn-sm'><MdEdit color="#ffffff" size={20} /></button>
            <button style={{marginLeft:5}} onClick={() => {props.removeSafetyReportSubItem(props.subItem.itemId)}} className='btn btn-danger btn-sm'><MdDeleteOutline color="#ffffff" size={20} /></button>

        </div>
    </div>
  )
}

export default SafetyReportSubItemScore